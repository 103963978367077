// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-customer-portals-portal-js": () => import("./../../../src/pages/customer-portals/portal.js" /* webpackChunkName: "component---src-pages-customer-portals-portal-js" */),
  "component---src-pages-customer-portals-portal-metric-billing-js": () => import("./../../../src/pages/customer-portals/portal-metric-billing.js" /* webpackChunkName: "component---src-pages-customer-portals-portal-metric-billing-js" */),
  "component---src-pages-customer-portals-seat-portal-js": () => import("./../../../src/pages/customer-portals/seat-portal.js" /* webpackChunkName: "component---src-pages-customer-portals-seat-portal-js" */),
  "component---src-pages-dashboard-js": () => import("./../../../src/pages/dashboard.js" /* webpackChunkName: "component---src-pages-dashboard-js" */),
  "component---src-pages-demo-1-js": () => import("./../../../src/pages/demo-1.js" /* webpackChunkName: "component---src-pages-demo-1-js" */),
  "component---src-pages-demo-2-js": () => import("./../../../src/pages/demo-2.js" /* webpackChunkName: "component---src-pages-demo-2-js" */),
  "component---src-pages-examples-list-js": () => import("./../../../src/pages/examples-list.js" /* webpackChunkName: "component---src-pages-examples-list-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-pricing-pages-flat-plus-metered-js": () => import("./../../../src/pages/pricing-pages/flat-plus-metered.js" /* webpackChunkName: "component---src-pages-pricing-pages-flat-plus-metered-js" */),
  "component---src-pages-pricing-pages-multi-unit-pricing-js": () => import("./../../../src/pages/pricing-pages/multi-unit-pricing.js" /* webpackChunkName: "component---src-pages-pricing-pages-multi-unit-pricing-js" */),
  "component---src-pages-pricing-pages-peloton-pricing-js": () => import("./../../../src/pages/pricing-pages/peloton-pricing.js" /* webpackChunkName: "component---src-pages-pricing-pages-peloton-pricing-js" */),
  "component---src-pages-pricing-pages-seat-pricing-js": () => import("./../../../src/pages/pricing-pages/seat-pricing.js" /* webpackChunkName: "component---src-pages-pricing-pages-seat-pricing-js" */),
  "component---src-pages-pricing-pages-standard-js": () => import("./../../../src/pages/pricing-pages/standard.js" /* webpackChunkName: "component---src-pages-pricing-pages-standard-js" */),
  "component---src-pages-pricing-pages-tax-vat-js": () => import("./../../../src/pages/pricing-pages/tax-vat.js" /* webpackChunkName: "component---src-pages-pricing-pages-tax-vat-js" */),
  "component---src-pages-saas-billing-flows-js": () => import("./../../../src/pages/saas-billing-flows.js" /* webpackChunkName: "component---src-pages-saas-billing-flows-js" */),
  "component---src-pages-test-js": () => import("./../../../src/pages/test.js" /* webpackChunkName: "component---src-pages-test-js" */),
  "component---src-pages-use-cases-freemium-js": () => import("./../../../src/pages/use-cases/freemium.js" /* webpackChunkName: "component---src-pages-use-cases-freemium-js" */),
  "component---src-pages-use-cases-metric-flow-js": () => import("./../../../src/pages/use-cases/metric-flow.js" /* webpackChunkName: "component---src-pages-use-cases-metric-flow-js" */),
  "component---src-pages-using-typescript-tsx": () => import("./../../../src/pages/using-typescript.tsx" /* webpackChunkName: "component---src-pages-using-typescript-tsx" */)
}

